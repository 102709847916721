import {
  Checkbox,
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Slider,
  Typography,
} from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Text from "antd/es/typography/Text";
import { eqProps } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import styled from "styled-components";
import { BASE_URL } from "../../ApiClient";
import { RootState } from "../../App";
import { Category } from "../categories/models";
import { Thumbnail } from "../circulars/models";
import { InputWrapper } from "../common/components/InputWrapper";
import { Actions } from "../Reducers";
import { getRecipes } from "./api";

export type AreaFormValues = {
  prefix: string;
  ingredient: string;
  deal: string;
  category?: Category;
  details: string;
};

const HoverDiv = styled.img`
  padding: 5px;
  &:hover {
    background-color: #18ff9099;
  }
`;

export const AreaForm = (props: {
  initialValues?: AreaFormValues;
  thumbnails?: Thumbnail[];
  onValuesChange?: (values: AreaFormValues) => void;
  categories?: Category[];
}) => {
  const defaultValues = {
    prefix: "",
    category: props.categories?.find(
      (c) => c.id === props.initialValues?.category?.id
    ),
    ingredient: "",
    deal: "",
    details: "",
  };
  const [values, setValues] = useState<AreaFormValues>(
    props.initialValues ?? defaultValues
  );
  const areaFormErrors = useSelector(
    (state: RootState) => state.editPage.editAreaFormErrors
  );
  useEffect(() => {
    const newValues = {
      details: props.initialValues?.details ?? defaultValues.details,
      deal: props.initialValues?.deal ?? defaultValues.deal,
      prefix: props.initialValues?.prefix ?? defaultValues.prefix,
      ingredient: props.initialValues?.ingredient ?? defaultValues.ingredient,
      category: props.initialValues?.category ?? defaultValues.category,
    };
    setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    if (
      Object.keys(values).filter(
        (it) => !eqProps(it, values, props.initialValues)
      ).length > 0
    ) {
      props.onValuesChange?.(values);
    }
  }, [values]);

  const dispatch = useDispatch();
  const categories = useSelector((state: RootState) => state.categories.data);
  const recipesLoading = useSelector(
    (state: RootState) => state.editPage.recipesLoading
  );
  const pageId = useSelector((state: RootState) => state.editPage.page?.id);
  const recipesDialogOpen = useSelector(
    (state: RootState) => state.editPage.recipesDialogOpen
  );
  const thumbnailSelectOpened = useSelector(
    (state: RootState) => state.editPage.thumbnailSelectOpened
  );
  const recipes = useSelector((state: RootState) => state.editPage.recipes);
  const thumbnails = useSelector(
    (state: RootState) => state.editPage.allThumbnails
  );

  const recipesQuery = useSelector(
    (state: RootState) => state.editPage.recipesQuery
  );

  const onChangeRecsNr = (value: number | undefined) => {
    dispatch(
      Actions.editPage.setRecipesQuery({
        ingredient: recipesQuery?.ingredient ?? "",
        nrOfRecs: value ?? 5,
      })
    );
  };
  // onFeaturedChange 
  const [featuredChecked, setFeaturedChecked] = useState(false);
  const onFeaturedChange = (e: CheckboxChangeEvent) => {
    console.log('checked = ', e.target.checked);
    setFeaturedChecked(e.target.checked);
  };
  return (
    <>
      <Modal
        maskClosable={false}
        zIndex={9001}
        title="Select Recipe"
        visible={recipesDialogOpen}
        onCancel={() => {
          dispatch(Actions.editPage.setRecModalVisible(false));
        }}
        okText="Select"
        onOk={() => {
          dispatch(Actions.editPage.setRecModalVisible(false));
        }}
      >
        <Text>{"Ingredient"}</Text>
        <Input
          defaultValue={recipesQuery?.ingredient}
          onChange={(event) => {
            dispatch(
              Actions.editPage.setRecipesQuery({
                ingredient: event.target.value,
                nrOfRecs: recipesQuery?.nrOfRecs ?? 5,
              })
            );
          }}
        />
        <Text>{"Number of recipes"}</Text>
        <Row>
          <Col span={12}>
            <Slider
              min={1}
              max={20}
              // @ts-ignore
              onChange={onChangeRecsNr}
              value={recipesQuery?.nrOfRecs ?? 5}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={1}
              max={20}
              style={{ margin: "0 16px" }}
              value={recipesQuery?.nrOfRecs ?? 5}
              onChange={onChangeRecsNr}
            />
          </Col>
        </Row>
        <Button
          loading={recipesLoading}
          style={{ marginTop: "1em" }}
          onClick={() => {
            dispatch(getRecipes(recipesQuery!));
          }}
        >
          {"Search recipes"}
        </Button>
        <Divider />
        <List
          bordered
          style={{
            maxHeight: "50vh",
            overflow: "auto",
          }}
          dataSource={recipes?.list}
          renderItem={(item) => (
            <List.Item
              style={
                recipes?.selected?.id === item.id
                  ? { background: "rgba(128,192,255,1)" }
                  : {}
              }
              onClick={() => {
                dispatch(Actions.editPage.selectRecipe(item));
              }}
            >
              <Typography.Text>{item.title}</Typography.Text>
              <img alt="" style={{ width: 40, height: 40 }} src={item.image} />
            </List.Item>
          )}
        />{" "}
      </Modal>
      <Modal
        maskClosable={false}
        zIndex={9001}
        title="Select Thumbnail"
        visible={thumbnailSelectOpened}
        onCancel={() => {
          dispatch(Actions.editPage.setThumbnailSelectOpened(false));
        }}
        okText="Add"
        onOk={() => {
          dispatch(Actions.editPage.setThumbnailSelectOpened(false));
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {thumbnails?.map((item) => (
            <Col className="gutter-row" span={6}>
              <HoverDiv
                onClick={() => {
                  dispatch(Actions.editPage.setAreaThumbnail(item.id));
                }}
                style={{ width: 80, height: "auto" }}
                src={`${BASE_URL}api/CircularPages/${pageId}/ThumbnailImage/${item.id}`}
              />
            </Col>
          ))}
        </Row>
      </Modal>

      <InputWrapper
        error={areaFormErrors?.prefix ? "Field is required" : undefined}
        label={"Prefix"}
      >
        <Input
          value={values.prefix}
          onChange={(event) =>
            setValues({
              ...values,
              prefix: event.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper
        error={areaFormErrors?.ingredient ? "Field is required" : undefined}
        label="Ingredient"
      >
        <Input
          value={values.ingredient}
          onChange={(event) =>
            setValues({
              ...values,
              ingredient: event.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper
        error={areaFormErrors?.deal ? "Field is required" : undefined}
        label={"Deal"}
      >
        <Input
          value={values.deal}
          onChange={(event) =>
            setValues({
              ...values,
              deal: event.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper
        error={areaFormErrors?.category ? "Field is required" : undefined}
        label={"Category"}
      >
        <Select
          value={values.category?.id}
          showSearch={true}
          filterOption={(inputValue, option) => {
            return (
              option?.children
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0 ?? false
            );
          }}
          onChange={(selectValue) => {
            setValues({
              ...values,
              category: categories.find((c) => c.id === selectValue),
            });
          }}
        >
          {categories.map((category) => (
            <Select.Option value={category.id}>{category.name}</Select.Option>
          ))}
        </Select>
      </InputWrapper>
      <InputWrapper
        error={areaFormErrors?.details ? "Field is required" : undefined}
        label={"Details"}
      >
        <Input.TextArea
          value={values.details}
          onChange={(event) =>
            setValues({
              ...values,
              details: event.target.value,
            })
          }
        />
      </InputWrapper>


      {/* <InputWrapper label={""}>
        <Checkbox checked={featuredChecked} onChange={onFeaturedChange}>
          Featured in carousel
        </Checkbox>
      </InputWrapper> */}
      <InputWrapper label={"Recipe"}>
        <div>
          <p style={{ fontWeight: "bold", margin: "0" }}>{recipes?.selected?.title ?? ""}</p>
          <Button
            loading={recipesLoading}
            type={"primary"}
            onClick={() => {
              dispatch(
                getRecipes({
                  ingredient: values.ingredient,
                  nrOfRecs: 5,
                })
              );
            }}
          >
            {"Select Recipe"}
          </Button>
        </div>
      </InputWrapper>
    </>
  );
};

export const ThumbnailPicker = (props: {
  pageId: string;
  thumbnails: Thumbnail[];
  onThumbnailClick: (id: string, index: number) => void;
  selectedImgIndex?: number | null;
}) => {
  return (
    <Col span={24}>
      <Divider>{"Choose thumbnail"}</Divider>
      <Row>
        {props.thumbnails.map((thumbnail, i) => {
          return (
            <Col span={4}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 4,
                  cursor: "pointer",
                  // border:
                  //   i === props.selectedImgIndex ? "1px solid #40a9ff" : "none",
                }}
              >
                <img
                  alt=""
                  style={{
                    width: "100px",
                    height: "auto",
                    margin: "16px",
                    maxWidth: "565",
                  }}
                  src={`${BASE_URL}api/CircularPages/${props.pageId}/ThumbnailImage/${thumbnail.id}`}
                  onClick={() => props.onThumbnailClick(props.pageId, i)}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};
