import React from "react";
import { Modal, Button } from "antd";
import { Recipe } from "./models";
import parse from "html-react-parser";
import { ClockCircleOutlined } from "@ant-design/icons";

interface RecipePreviewModalProps {
  // recipe: Recipe;
  recipe: any;
  visible: boolean;
  onCancel: () => void;
}
export const RecipePreviewModal: React.FC<RecipePreviewModalProps> = ({
  recipe,
  visible,
  onCancel,
}) => {
  console.log("title", recipe?.content?.ingredientLines);

  return (
    <Modal
      title={recipe.title || recipe.content?.details?.name}
      visible={visible}
      footer={<Button onClick={onCancel}>Close</Button>}
      destroyOnClose
      onCancel={onCancel}
      bodyStyle={{ maxHeight: "60vh", overflowX: "scroll" }}
      centered
    >

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={recipe.image || recipe.content?.details?.images[0]?.resizableImageUrl} alt={recipe.content?.details?.displayName} style={{ maxWidth: "100%" }} />

        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            display: "flex",
            // border: "1px solid red",
          }}
        >
          {recipe.content?.details?.totalTime && recipe.content?.details?.totalTimeInSeconds ? (
            <>
              <ClockCircleOutlined
                style={{
                  marginRight: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <span style={{ marginRight: 16 }}>
                Preparation: {recipe.content?.details?.totalTime} minutes
              </span>
              <span>Cooking time: {recipe.content?.details?.totalTimeInSeconds} minutes </span>
            </>
          ) : null}
        </div>
        <p>
          {recipe.content?.description?.text
            ? parse(recipe.content?.description?.text, {
              replace: (node: any) => {
                if (node.name === "a") {
                  return <span>{node.children[0].data}</span>;
                }
              },
            })
            : null}
        </p>


        <h4>Ingredients</h4>
        <ul
          style={{ listStyleType: "circle", listStylePosition: "inside" }}
        >
          {recipe?.content?.ingredientLines.map((ingredient: any, index: number) => (
            <li key={index}>
              <span>{ingredient.ingredient}</span>
            </li>
          ))}
        </ul>

        {//@ts-ignore
          recipe.analyzedInstructions ? (
            //@ts-ignore
            recipe.analyzedInstructions[0] ? (
              //@ts-ignore
              recipe.analyzedInstructions[0]?.steps.length ? (
                <div style={{ marginTop: 16 }}>
                  <h4>Instructions</h4>

                  <ul
                    style={{
                      listStyleType: "circle",
                      listStylePosition: "inside",
                    }}
                  >
                    {//@ts-ignore
                      recipe.analyzedInstructions[0].steps.map(
                        (instruction: any, index: number) => (
                          <li key={index}>{instruction.step}</li>
                        )
                      )}
                  </ul>
                </div>
              ) : null
            ) : recipe.instructions ? (
              <div style={{ marginTop: 16 }}>
                <h4>Instructions</h4>
                <p>{recipe.instructions}</p>
              </div>
            ) : null
          ) : null}

        {recipe && (
          <a href={recipe.content?.details?.attribution?.url} style={{ marginTop: 16 }}>
            Source
          </a>
        )}
      </div>
    </Modal>
  );
};
