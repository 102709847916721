import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CircularArea, CircularPage, Thumbnail } from "../circulars/models";
import { getPageDetails, getRecipes, saveArea } from "./api";
import * as R from "ramda";
import { v4 as uuidv4 } from "uuid";
import { AreaFormValues } from "./AreaForm";

export type Recipes = {
  selected: any;
  list: any[];
};

interface EditPageState {
  id?: string;
  page?: CircularPage;
  inEditArea?: CircularArea;
  dimensions?: { width: number; height: number };
  thumbnails?: Thumbnail[];
  circularAreas?: CircularArea[];
  showThumbnails: boolean;
  editFormValues?: AreaFormValues;
  recipes?: Recipes;
  recipesLoading?: boolean;
  recipesDialogOpen?: boolean;
  saveLoading?: boolean;
  thumbnailSelectOpened?: boolean;
  moving: boolean;
  creatingNewRegion?: boolean;
  inCreatingRegion?: Partial<CircularArea>;
  allThumbnails: Thumbnail[];
  editAreaFormErrors?: AreaFormValues;
  newArea?: boolean;
  recipesQuery?: {
    ingredient: string;
    nrOfRecs: number;
  };
}

export const mapPercentage = (
  value: number,
  in_min: number,
  in_max: number
) => {
  return value / in_max;
};

// const valueInRange = (value: number, min: number, max: number) => {
//   return value >= min && value <= max;
// };

export const editPageSlice = createSlice({
  name: "editPage",
  initialState: {
    showThumbnails: true,
  } as EditPageState,
  reducers: {
    setAreaFormErrors: (state, action: PayloadAction<AreaFormValues>) => {
      state.editAreaFormErrors = action.payload;
    },
    startCreatingRegion: (
      state,
      action: PayloadAction<{ x: number; y: number }>
    ) => {
      state.recipes = undefined;
      state.editFormValues = undefined;
      state.newArea = true;
      state.inEditArea = {
        top: mapPercentage(action.payload.y, 0, state.dimensions!.height),
        left: mapPercentage(action.payload.x, 0, state.dimensions!.width),
        width: 100,
        height: 160,
      } as CircularArea;
    },
    cancelEditRegion: (state) => {
      state.inEditArea = undefined;
      state.newArea = false;
    },
    onResize: (
      state,
      action: PayloadAction<{
        x: number;
        y: number;
        width: number;
        height: number;
      }>
    ) => {
      state.inEditArea!.top = mapPercentage(
        action.payload.y,
        0,
        state.dimensions!.height
      );
      state.inEditArea!.left = mapPercentage(
        action.payload.x,
        0,
        state.dimensions!.width
      );
      state.inEditArea!.width = mapPercentage(
        action.payload.width,
        0,
        state.dimensions!.width
      );
      state.inEditArea!.height = mapPercentage(
        action.payload.height,
        0,
        state.dimensions!.height
      );
    },
    setMoving: (state, action) => {
      state.moving = action.payload;
    },
    setAreaThumbnail: (state, action: PayloadAction<number>) => {
      if (state.inEditArea) {
        state.inEditArea.thumbnailIndex = action.payload;
      }
      state.thumbnailSelectOpened = false;
    },
    setThumbnailSelectOpened: (state, action: PayloadAction<boolean>) => {
      state.thumbnailSelectOpened = action.payload;
    },
    selectRecipe: (state, action: PayloadAction<any>) => {
      state.recipes = {
        list: state.recipes?.list ?? [],
        selected: action.payload,
      };
    },
    setRecModalVisible: (state, action: PayloadAction<boolean>) => {
      state.recipesDialogOpen = action.payload;
    },
    setAreaFormValues: (state, action: PayloadAction<AreaFormValues>) => {
      state.editFormValues = {
        ...state.editFormValues,
        ...action.payload,
      };
    },
    areaEdited: (state, action: PayloadAction<any>) => {
      state.inEditArea = { ...state.inEditArea, ...action.payload };
    },
    deleteArea: (state, _: PayloadAction) => {
      state.inEditArea = undefined;
      state.newArea = false;
    },
    toggleShowThumbnails: (state, _: PayloadAction) => {
      state.showThumbnails = !state.showThumbnails;
    },
    editFromThumbnail: (
      state,
      action: PayloadAction<{ thumb: Thumbnail; index: number }>
    ) => {
      const { thumb } = action.payload;
      state.recipes = undefined;
      state.newArea = true;
      state.editFormValues = undefined;
      state.editAreaFormErrors = undefined;
      state.inEditArea = {
        id: uuidv4(),
        thumbnailIndex: thumb.id,
        height: thumb.height,
        width: thumb.width,
        top: thumb.top,
        left: thumb.left,
      } as CircularArea;
    },
    pageLoaded: (state, action: PayloadAction<CircularPage>) => {
      state.circularAreas = action.payload.circularAreas;
      let thumbs = [] as (Thumbnail & { id: number })[];
      const rawThumbs = action.payload.thumbnails.map((t, i) => {
        return {
          ...t,
          id: i,
        };
      });
      state.allThumbnails = rawThumbs;
      for (let i of action.payload.circularAreas) {
        thumbs = R.remove(i.thumbnailIndex, 1, rawThumbs);
      }
      if (thumbs.length === 0) {
        thumbs = rawThumbs;
      }
      state.thumbnails = thumbs.filter((t) => {
        const overSized = t.width > 0.9 || t.height > 0.9;
        return !overSized;
      });
    },
    setDimensions: (
      state,
      action: PayloadAction<{ width: number; height: number }>
    ) => {
      state.dimensions = action.payload;
    },
    areaDragged: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.inEditArea!.top = mapPercentage(
        action.payload.y,
        0,
        state.dimensions!.height
      );
      state.inEditArea!.left = mapPercentage(
        action.payload.x,
        0,
        state.dimensions!.width
      );
    },
    editArea: (state, action: PayloadAction<CircularArea | undefined>) => {
      state.editAreaFormErrors = undefined;
      state.inEditArea = action.payload;
      state.editFormValues = action.payload;
      if (
        !!action.payload?.recepiesJSON &&
        action.payload?.recepiesJSON.length > 0
      ) {
        state.recipes = JSON.parse(action.payload?.recepiesJSON);
      }
    },
    deleteInEditArea: (state, action: PayloadAction<string>) => {
      let newPage: CircularPage = {} as CircularPage;
      Object.assign(newPage, state.page);
      newPage.circularAreas = newPage.circularAreas.filter(
        (area) => area.id !== action.payload
      );
      state.page = newPage;
      state.inEditArea = undefined;
      state.newArea = false;
    },
    editPage: (
      state,
      action: PayloadAction<{ id: string; page: CircularPage }>
    ) => {
      state.id = action.payload.id;
      state.page = action.payload.page;
    },
    setRecipesQuery: (
      state,
      action: PayloadAction<{
        nrOfRecs: number;
        ingredient: string;
      }>
    ) => {
      state.recipesQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPageDetails.fulfilled, (state, action) => {
      state.page = action.payload;
    });
    builder.addCase(saveArea.pending, (state) => {
      state.saveLoading = true;
      state.editAreaFormErrors = undefined;
    });
    builder.addCase(saveArea.rejected, (state) => {
      state.saveLoading = false;
    });

    builder.addCase(saveArea.fulfilled, (state) => {
      state.saveLoading = false;
      state.inEditArea = undefined;
      state.newArea = false;
    });

    builder.addCase(getPageDetails.pending, (state) => {
      state.dimensions = undefined;
      state.page = undefined;
      state.inEditArea = undefined;
      state.newArea = false;
    });

    // builder.addCase(postPage.fulfilled, (state, action) => {
    //   // alert("did it!");
    // });
    builder.addCase(getRecipes.rejected, (state) => {
      state.recipesLoading = true;
      state.recipes = {
        list: [],
        selected: undefined,
      };
    });

    builder.addCase(getRecipes.pending, (state) => {
      state.recipesLoading = true;
    });
    builder.addCase(getRecipes.fulfilled, (state, action: any) => {
      state.recipesLoading = false;
      state.recipes = {
        list: action.payload.list,
        selected: undefined,
      };
      state.recipesQuery = {
        ingredient: action.payload.ingredient,
        nrOfRecs: action.payload.nrOfRecs,
      };
      state.recipesDialogOpen = true;
    });
  },
});
